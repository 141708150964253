* {
    box-sizing: border-box !important;
    font-family: chromatic-pro-regular, sans-serif;
}

.removeArrowsFromInputTypeNumber {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}

[class^=' css-'][class$='-menu'] {
    /* Custom Kaptyn override CSS styles */

    @media (min-width: 900px) {
        /* styles for screens that are 900px wide or less go here */
        width: 200%;
        border-radius: 8px;
    }
}